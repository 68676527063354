.checkbox {
  $root: &;
  cursor: pointer;

  &:hover {
    #{$root}__label {
      color: $checkboxLabelColor;
    }

    #{$root}__box {
      background-color: $inputFocusBackgroundColor;
      border-color: $inputFocusBorderColor;
    }
  }

  &--disabled {
    opacity: .5;
    pointer-events: none;
    cursor: default;
  }

  &__wrapper {
    padding-left: $checkboxLabelPaddingLeftMobile;
    position: relative;
    display: inline-block;

    @media (min-width: $screen-tablet-portrait) {
      padding-left: $checkboxLabelPaddingLeftDesktop;
    }
  }

  &__input {
    display: none;

    &:checked {
      + #{$root}__box {
        border-color: $checkboxCheckedBorderColor;

        @include iconFont(checkSmall) {
          font-size: 10px;
          color: $checkboxCheckedColor;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          animation: fadeIn .1s;
        }

        + #{$root}__label {
          color: $checkboxLabelColor;
        }
      }
    }
  }

  &__box {
    display: block;
    width: $checkboxWidth;
    height: $checkboxWidth;
    border: 1px solid $inputBorderColor;
    position: absolute;
    left: 0;
    top: $checkboxBoxTop;
    transform: $checkboxBoxTransform;
    background: $checkboxBackground;
    border-radius: $checkboxBorderRadius;
  }

  &__label {
    font-size: $checkboxLabelFontSizeMobile;
    line-height: $checkboxLabelLineHeight;
    color: $checkboxLabelColor;
    display: inline-block;

    @media (min-width: $screen-tablet-portrait) {
      font-size: $checkboxLabelFontSizeDesktop;
    }

    a {
      color: $brandPrimaryColor;
    }
  }

  &__error {
    margin: 4px 0;
  }

  &--box-hidden {
    #{$root}__box {
      display: none;
    }
  }

  &--switch {
    display: inline-block;

    #{$root}__wrapper {
      display: flex;
      padding: 0;
      margin: $checkboxSwitchWrapperMargin;
      justify-content: flex-start;
      flex-direction: row;
      align-items: center;
      width: fit-content; // stylelint-disable-line plugin/no-unsupported-browser-features
    }

    #{$root}__input {
      display: inherit;
      order: 1;
    }

    #{$root}__box {
      position: relative;
      appearance: none; // stylelint-disable-line plugin/no-unsupported-browser-features
      width: $filterSwitchWidth;
      min-width: $filterSwitchWidth;
      height: $filterSwitchHeight;
      background: $filterSwitchColor;
      border-radius: $filterSwitchHeight;
      box-shadow: $filterSwitchShadow;
      cursor: pointer;
      transition: .4s;
      order: 2;
      margin: $checkboxSwitchMargin;
      align-self: flex-start;
      border: none;
      left: auto;
      top: auto;
      transform: none;

      &::before {
        display: none;
      }

      &::after {
        position: absolute;
        content: "";
        width: $filterSwitchHeight;
        height: $filterSwitchHeight;
        top: 0;
        left: 0;
        background: $filterSwitchBackgroundColor;
        border-radius: 50%;
        box-shadow: $filterSwitchToggleShadow;
        transition: .4s;
      }
    }

    #{$root}__label {
      padding: $checkboxSwitchLabelMargin;
      order: 2;
    }

    input[type="checkbox"] {
      display: none;
    }

    input:checked + #{$root}__box {
      background: $filterSwitchActiveColor;

      &::before {
        display: none;
      }

      &::after {
        left: calc(50% + 2px);
      }
    }

    &:hover {
      #{$root}__box {
        background: $filterSwitchColor;
      }

      input:checked + #{$root}__box {
        background: $filterSwitchActiveColor;
      }
    }

    &#{$root}--box-hidden {
      display: block;
      height: 0;
      overflow: hidden;

      #{$root}__box,
      input[type="checkbox"] {
        opacity: 0;
      }
    }
  }
}
