$indexFlyout: 495;
$indexHeader: 1300;
$indexHeaderSearchActive: 1300;
$indexFooter: 490;
$indexTooltip: 600;
$indexBasketFlyout: 750;
$indexImagePreview: 900;
$indexFullScreenGallery: 1100;
$indexPageOverlay: 1350;
$indexPageOverlayVisibleTransparent: 1200;
$indexDatePicker: 1450;
$indexCookies: 1350;
$indexBurgerMenu: 1300;
$indexPlpFilters: 1400;
$indexModal: 1500;
$indexPdpNavi: 495;
$indexToolbar: 800;
$indexLightbox: 1400;
// App
$indexPageOverlaySearch: 610;
$indexPageOverlayNativeApp: 590;
$indexBurgerMenuNativeApp: 1302;
$indexAppNav: 1303;
$indexAppHeaderMenu: 1302;
$indexAppAccountMenu: 1301;
$indexPlpFiltersApp: 1098;
$indexAppCookieLightBox: 1400;
