@import '../../../bps/components/voteInput/voteInput.scss';

.vote-input {
  &__label {
    border-color: $grayLighterColor;
    width: 60px;
    height: 60px;

    @media (min-width: $screen-desktop) {
      width: 35px;
      height: 35px;
    }

    &--checked {
      border-color: transparent;
      cursor: auto;
    }
  }
}
