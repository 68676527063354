@import '../../../bps/components/input/TextInput.scss';

.input {
  &__wrapper {
    height: 50px;
    border-radius: 10px;
    border: 1px solid $grayLightColor;

    @media (min-width: $screen-tablet-portrait) {
      height: 44px;
    }

    &--valid {
      font-size: 16px;
    }

    &--textarea {
      .input__field--textarea {
        padding: 5px 15px 0 0;
        line-height: 1.4;
      }

      &.input__wrapper--valid::after {
        top: 20px;
      }
    }
  }

  &__hint {
    font-size: $fontSizeXSmall;
    margin: 8px 8px 4px;
  }

  &__field {
    font-weight: $fontWeightMedium;
    line-height: 2em;

    &::placeholder {
      line-height: 1.4;

      @media (max-width: $screen-tablet-portrait) {
        font-size: $fontSizeSmall;
      }
    }
  }

  &__label {
    &.headline,
    &--static {
      color: $blackColor;
      margin-bottom: 4px;

      @media (min-width: $screen-tablet-portrait) {
        margin-bottom: 6px;
      }
    }
  }

  &__label:not(.input__label--static) {
    top: 5px;
    font-size: $fontSizeXXSmall;

    @media (min-width: $screen-tablet-portrait) {
      top: 7px;
      font-size: 10px;
    }
  }
}
