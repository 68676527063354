@use "sass:math";

$columnCount: 12;

.form {
  width: 100%;

  // group and rows with cols
  &__group {
    margin-bottom: 16px;

    &--action-bar {
      margin-top: 25px;
    }

    &--row {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: stretch;
      margin-left: -5px;
      margin-right: -5px;
    }

    &--row-wrapper {
      width: 100%;
    }

    &--hide {
      display: none;
    }

    &--inline {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      margin-left: -10px;
      margin-right: -10px;

      & > * {
        margin: 0 10px;
      }
    }

    &--inline-strech {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: stretch;
      margin: 30px -10px 0;

      .button {
        margin: 0 10px 20px;
      }
    }

    &__inner-form {
      margin: -10px 0 40px 30px;
    }

    &__inner-form-text {
      padding: 10px 0 0;
    }

    &--row-button {
      margin-top: 20px;
    }
  }

  @for $i from 1 through $columnCount {
    &__col-#{$i} {
      padding: 0 5px 10px;
      width: 100%;

      @media (min-width: $screen-mobile-landscape) {
        width: calc(100% / #{math.div($columnCount, $i)});
      }

      // for use in checkout sidepanel
      // force all columns to be 100% width
      .form-in-column & {
        width: 100%;
      }
    }
  }

  &__col {
    padding: 0 5px 10px;
    width: 100%;
  }

  &__col--text {
    padding: 5px;
  }

  &__col--sub-padding {
    padding: 10px 0 30px;

    @media (min-width: $screen-tablet-portrait) {
      padding: 10px 0 30px 32px;
    }
  }

  &__col--relative {
    position: relative;
  }

  // elements
  &__headline {
    font-size: $fontSizeXMedium;
    margin-bottom: 20px;
    font-weight: $fontWeightSemiBold;
  }

  &__subline {
    font-size: $fontSizeMedium;
    margin: 10px 0;
    font-weight: $fontWeightSemiBold;
  }

  &__link {
    display: inline-block;
    color: $textGrayColor;
    text-decoration: underline;
    margin-bottom: 3px;

    &:visited {
      color: $textGrayColor;
    }

    &:hover {
      color: $textHoverColor;
    }
  }

  &__text {
    margin: 5px 0 10px;
    line-height: 1.3;

    a {
      color: currentColor;
    }
  }

  &__text-info {
    padding: 10px 0;
    line-height: 1.5;
  }

  &__radio-line {
    min-height: 50px;
  }

  &__radio-sub-element {
    padding: 16px 32px 0;
  }

  &__message {
    margin: 20px 0;
    line-height: 1.3;

    &__text {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    &--error {
      color: $errorColor;
    }

    &--warning {
      color: $warningColor;
    }

    &--success {
      color: $successColor;
    }

    &--info {
      color: $infoColor;
    }

    &--small-margin {
      margin: 8px 0 0;
    }
  }

  &__submit-loader {
    padding: 20px;
    margin: 10px auto;

    @media (min-width: $screen-mobile-landscape) {
      margin: 0;
    }
  }

  &__element-container {
    display: flex;
    flex-wrap: wrap;
    margin: $formElementSpacing * -1;
    padding: $formElementSpacing 0;
  }

  &__element {
    padding: $formElementSpacing;
  }

  &__mandatory-text {
    font-size: $fontSizeXSmall;
    margin: 0 0 8px;
    width: 100%;
    line-height: 1.2;

    .user-area__box-container & {
      margin: 0 0 16px 10px;
    }

    a {
      color: inherit;
    }
  }

  &__mandatory-text-block {
    font-size: 14px;
    display: block;

    @media (min-width: $screen-tablet-portrait) {
      margin-top: 0;
    }
  }

  &__option-area {
    background: $whiteColor;
    border-radius: 4px;
    padding: 16px 20px;
    border: 2px solid transparent;
    box-shadow: 0 0 0 rgb(0 0 0 / .5);
    transition: border .2s ease-in-out, box-shadow .2s ease-in-out;

    &:has(input:checked) { // stylelint-disable-line
      border-color: $inputRadioCheckedColor;
      box-shadow: 0 2px 4px rgb(0 0 0 / .3);
    }

    & + & {
      margin-top: 8px;
    }
  }

  &__option-area-input {
    &.radio-input {
      float: none;
      align-items: flex-start;
      margin: 0;
    }
  }

  &__edit-btn {
    position: absolute;
    top: 16px;
    right: 16px;
    border: none;
    background: transparent;
    cursor: pointer;
    z-index: 1;
    font-size: $fontSizeSmall;
    text-decoration: underline;
  }
}
