@import '../../../bps/components/radioInput/radioInput.scss';

.radio-input {
  $root: &;

  &__radio {
    border: 1px solid $textDarkestColor;
  }

  &__sprite-icon {
    & > [class^='sprite-'] {
      &.sprite-hermeslogo {
        box-shadow: none;
        border-radius: 0;
        margin: 0 6px;
      }
    }
  }

  @media (max-width: $screen-tablet-landscape) {
    margin: 8px 4px;
  }
}
