.date-picker {
  $root: &;
  font-size: $datePickerFontSize;
  background: $datePickerDayBackgroundColor;
  border: $datePickerHeaderBackgroundColor 1px solid;

  &__days-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    padding: 0;
    color: $datePickerTextColor;
  }

  &__day {
    width: calc(100% / 7);
    text-align: center;
  }

  &__day-label {
    display: block;
    width: 100%;
    height: 100%;
    padding: 10px;
    cursor: pointer;
    text-align: center;

    &:hover {
      background: $datePickerHoverColor;
      color: $datePickerHoverTextColor;
    }
  }

  &__day-input {
    display: none;

    &:checked {
      + #{$root}__day-label {
        background: $datePickerCheckedColor;
        color: $datePickerHoverTextColor;
      }
    }

    &:disabled {
      + #{$root}__day-label {
        color: $textLightGrayColor;
        cursor: not-allowed;

        &:hover {
          background: transparent;
        }
      }
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 5px;
    background-color: $datePickerHeaderBackgroundColor;

    &__days {
      color: $datePickerHeaderTextColor;
      padding: 0 5px 5px;
    }
  }

  &__select-wrapper {
    position: relative;
    padding: 0 20px;
    height: 32px;
  }

  &__select {
    border: none;
    height: 100%;
    width: 100%;
    appearance: menulist; // stylelint-disable-line  plugin/no-unsupported-browser-features
    text-align: center;
    cursor: pointer;
    margin-right: 25px;
    padding-left: 5px;
    padding-right: 5px;
    background: $whiteColor;
    font-size: $datePickerFontSize;
    color: $datePickerSelectColor;

    &:hover,
    &:active {
      outline: none;
    }
  }

  &__change-select-button {
    width: 15px;
    height: 100%;
    cursor: pointer;
    position: absolute;
    top: 0;

    &::before {
      color: $textLightColor;
      font-size: 10px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-weight: bold;
    }

    &--prev {
      left: 0;

      @include iconFont(arrowLeft);
    }

    &--next {
      right: 0;

      @include iconFont(arrowRight);
    }

    &--disabled {
      box-shadow: none;
      cursor: not-allowed;

      &::before {
        color: $datePickerArrowDisabledColor;
      }
    }
  }

  &__save-button-wrapper {
    text-align: right;
    padding: 8px;
  }
}
