.radio-input {
  $root: &;
  position: relative;
  cursor: pointer;
  margin: 0;
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  float: left;

  &:hover {
    #{$root}__radio {
      background-color: $inputFocusBackgroundColor;
      border-color: $inputFocusBorderColor;
    }
  }

  .radio-input--inline & {
    white-space: nowrap;
  }

  &--disabled {
    opacity: .5;
    cursor: not-allowed;
    user-select: none;
  }

  &__wrapper {
    position: relative;
    display: block;
    padding-left: 25px;
  }

  &__row {
    padding: 20px 0 0;

    &:last-child {
      padding: 10px 0 15px;
    }

    &--default-padding {
      padding: 20px 0 0 !important;
    }
  }

  &__input {
    @include visually-hidden();

    + #{$root}__radio {
      &::before {
        content: '';
        position: absolute;
        height: 13px;
        width: 13px;
        border-radius: 50%;
        background: $inputRadioCheckedColor;
        display: block;
        top: 2px;
        left: 2px;
        transform: scale(0);
        transition: transform .2s ease-out;
      }
    }

    &:checked {
      + #{$root}__radio {
        &::before {
          transform: scale(1);
        }
      }

      ~ #{$root}__label {
        color: $inputRadioLabelCheckedColor;
      }

      ~ #{$root}__thumb {
        border-color: $textDarkestColor;
      }

      ~ #{$root}__box {
        color: $textDarkestColor;
        border-color: $textDarkestColor;
      }
    }

    &:disabled {
      + #{$root}__radio,
      ~ #{$root}__label,
      ~ #{$root}__box,
      + #{$root}__thumb {
        opacity: .5;
        cursor: not-allowed;
      }
    }
  }

  &__radio {
    height: 19px;
    width: 19px;
    min-width: 19px;
    border: 1px solid $inputBorderColor;
    background: $inputRadioBackgroundColor;
    border-radius: 50%;
    display: inline-block;
    position: relative;

    .is-test-env & {
      &::before {
        transition-duration: 0s;
        transition-delay: 0s;
      }
    }
  }

  &__label {
    color: $inputRadioLabelColor;
    line-height: 1.3;
    font-size: $inputRadioLabelFontSize;
    font-weight: $fontWeightRegular;
    padding: 2px 20px 2px 10px;
    display: inline-block;
  }

  &__thumb {
    display: inline-block;
    width: 100%;
    border: 1px solid transparent;
    border-radius: 15%;
    padding: 2px;
    overflow: hidden;
  }

  &__box {
    position: relative;
    height: 40px;
    width: auto;
    min-width: 40px;
    text-align: center;
    padding: 0 5px;
    border: 2px solid $borderLightColor;
    font-weight: $fontWeightSemiBold;
    font-size: 10px;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      color: $textDarkestColor;
      border-color: $textDarkestColor;

      #{$root}__input:disabled ~ &,
      #{$root}--disabled & {
        color: $textDarkestColor;
        opacity: .5;
        border-color: $borderLightColor;
      }
    }

    &::after {
      content: '';
      position: absolute;
      width: calc(100% - 4px);
      height: calc(100% - 4px);
      top: 0;
      left: 0;
      border: 2px solid $borderWhiteColor;
    }
  }

  &__description {
    display: none;
    font-size: $fontSizeSmall;
    color: $textMediumGrayColor;
    width: 100%;

    #{$root}--selected & {
      display: block;
    }
  }

  &__sprite-icon {
    display: flex;
    align-items: center;

    & > [class^='sprite-'] {
      position: relative;
      display: inline-block;
      min-width: $inputIconSpriteMinWidth;
      margin: 0 15px 0 0;
    }
  }

  &__sprite-text {
    display: inline-block;
  }

  &__sprite-text-sub {
    color: $grayColor;
    font-weight: $fontWeightRegular;
  }
}
