.password-input {
  $root: &;
  position: relative;

  &__toggle-icon {
    @include iconFont(eye);
    display: block;
    position: absolute;
    padding: 12px 8px;
    top: 4px;
    right: 3px;
    color: $passwordInputIconColor;
    cursor: pointer;

    #{$root}--text & {
      @include iconFont(close, after) {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  &__toggle-text {
    display: block;
    text-align: right;
    margin: 8px 0 0;
    text-decoration: underline;
    color: $passwordInputTextColor;
    cursor: pointer;
    font-size: $fontSizeXSmall;

    @media (min-width: $screen-tablet-portrait) {
      font-size: $fontSizeSmall;
    }
  }

  .input {
    &__wrapper {
      &::after {
        content: '';
        display: none;
      }

      .input__field {
        padding-right: 0;
        margin-right: 34px;
      }
    }
  }
}
