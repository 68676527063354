.textarea {
  display: block;

  &__error {
    margin-top: 8px;
    position: relative;
    z-index: 5;
  }

  &__label {
    font-size: $fontSizeSmall;
    display: block;
    margin: 5px 0;
  }

  &__field {
    resize: none;
    width: 100%;
    padding: 5px 15px;
    min-height: 100px;
    font-size: $baseFontSize;
    border: 1px solid $borderDefaultColor;
    transition: border .2s, box-shadow .2s;

    &:focus {
      outline: none;
    }

    &:hover,
    &:focus {
      border-color: $brandPrimaryColor;
    }

    &--disabled {
      opacity: .55;
    }

    &--error {
      border: 1px solid $inputErrorBorderColor;

      &:hover {
        border: 1px solid darken($inputErrorBorderColor, 15);
      }
    }

    &--success {
      border: 1px solid $inputSuccessBorderColor;

      &:hover {
        border: 1px solid darken($inputSuccessBorderColor, 15);
      }
    }

    &--no-label {
      margin: 10px 0;
    }

    &::placeholder {
      color: $textGrayColor;

      @include fontSize($fontSizeSmall);
    }
  }
}
