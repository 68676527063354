.vote-input {
  $root: &;
  display: inline-block;
  align-items: center;
  margin: 0 5px;

  &__inputs {
    display: flex;
    flex-shrink: 1;
  }

  &__radio {
    display: none;
  }

  &__label {
    font-size: 20px;
    cursor: pointer;
    position: relative;
    color: $voteInputColor;
    border: $voteInputBorder;
    background: $voteInputBackground;
    width: 35px;
    height: 35px;
    transition: background-color .2s;

    &::before {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    &--positive {
      @include iconFont(checked);

      &#{$root}__label--checked {
        color: $voteInputCheckedPositiveColor;
        background: $voteInputCheckedPositiveBackground;
      }
    }

    &--negative {
      @include iconFont(plus) {
        transform: translate(-50%, -50%) rotate(45deg);
      }
      margin-left: -1px;

      &#{$root}__label--checked {
        color: $voteInputCheckedNegativeColor;
        background: $voteInputCheckedNegativeBackground;
      }
    }
  }

  &__error {
    width: 100%;
  }
}
