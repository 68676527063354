.captcha-input {
  margin: 16px 0;
  min-height: 80px;

  &--invisible {
    margin: 0;
    min-height: 0;
  }
}

// placeholder
captcha-input {
  min-height: 80px;
}
