.input-error {
  @include fontSize($inputErrorFontSize);
  display: block;
  line-height: 1;
  color: $textErrorColor;
  margin-top: 4px;

  a {
    color: $textErrorColor;
  }

  &--animation {
    &-enter-active,
    &-leave-active {
      transition: transform .15s, opacity .15s;
    }

    &-enter,
    &-leave-to {
      opacity: 0;
      transform: translateY(-8px);
    }
  }
}
