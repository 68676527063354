.input-warning {
  @include fontSize();
  display: block;
  line-height: 1.4;
  color: $textWarningColor;

  a {
    color: $textWarningColor;
  }
}
