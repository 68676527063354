.date-input {
  position: relative;

  &--active {
    z-index: $indexDatePicker;
  }

  &__input {
    position: relative;
    z-index: 3;
  }

  &__icon {
    cursor: pointer;

    @include iconFont(calendar) {
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 4;
      font-size: 20px;
      color: $datePickerIconColor;
    }
  }

  &__label {
    font-size: 10px;
    display: block;
    margin: 0;
    max-height: 50px;
    overflow: hidden;
    transform: translate(0, 0);
    transition: transform .15s ease-out, opacity .05s ease-out;
    opacity: 1;
    position: absolute;
    z-index: 10;
    top: 7px;
    left: 13px;
    line-height: 1.3;

    @media (min-width: $screen-tablet-portrait) {
      font-size: 10px;
    }

    &--only-mobile {
      @media (min-width: $screen-tablet-portrait) {
        display: none;
      }
    }

    &--as-placeholder {
      pointer-events: none;
      opacity: 0;
      transform: translate(3px, 8px);
      padding-bottom: 0;
      font-size: 14px;

      & + .date-input__input .input__wrapper .input__field {
        padding: 7px 11px;
      }
    }

    & + .date-input__input .input__field {
      padding: 15px 15px 3px 11px;
    }

    &.headline,
    &--static {
      position: relative;
      top: auto;
      left: auto;
      font-size: $inputFieldFontSizeMobile;

      @media (min-width: $screen-tablet-portrait) {
        font-size: $inputFieldFontSizeDesktop;
      }
    }
  }

  &__date-picker {
    position: absolute;
    z-index: 2;
    background: $datePickerDayBackgroundColor;
    max-width: 251px;
    min-width: $datePickerWidth;
  }

  &__error {
    position: relative;
    z-index: 1;
  }

  &__wrapper-background {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgb(0 0 0 / 0);
    transition: opacity 500ms;
    opacity: 1;
    z-index: 1;
  }
}
